
















import Vue, { PropOptions } from 'vue';
import { mapGetters } from 'vuex';
import { NeIcon } from '@ne/ne-vue2-lib';
import { ResourceItemsFile } from '@/api/models/resource';
import { URI_RESOURCE } from '@/api/endpoints';
import { GET_API_URL } from '@/store/getters';

export default Vue.extend({
  name: 'ResourcesSectionTileFileLink',
  components: {
    NeIcon
  },
  props: {
    file: {
      type: Object,
      required: true
    } as PropOptions<ResourceItemsFile>
  },
  computed: {
    ...mapGetters({
      getApiUrl: GET_API_URL
    })
  },
  methods: {
    getFileLink (fileId: number): string {
      const path = URI_RESOURCE(fileId);
      return this.getApiUrl(path);
    }
  }
});
