



















import Vue, { PropOptions } from 'vue';
import { NeTile } from '@ne/ne-vue2-lib';
import ResourcesSctionTileTopicFiles from './TopicFiles/TopicFiles.vue';
import { ResourceItem } from '@/api/models/resource';

export default Vue.extend({
  name: 'ResourcesSctionTile',
  components: {
    NeTile,
    ResourcesSctionTileTopicFiles
  },
  props: {
    sectionData: {
      type: Object,
      required: true
    } as PropOptions<ResourceItem>
  }
});
