
































































import Vue, { PropOptions } from 'vue';
import { mapGetters } from 'vuex';
import { NeIcon, NeCollapse, NeCollapseHeader } from '@ne/ne-vue2-lib';
import ResourcesSectionTileFileLink from '../FileLink/FileLink.vue';
import ResourcesSectionTileVideoPreview from '../VideoPreview/VideoPreview.vue';
import { ResourceItem, ResourceItemsFile } from '@/api/models/resource';
import { URI_RESOURCES } from '@/api/endpoints';
import { GET_API_URL } from '@/store/getters';

export default Vue.extend({
  name: 'ResourcesSectionTileTopicFiles',
  components: {
    NeIcon,
    NeCollapse,
    NeCollapseHeader,
    ResourcesSectionTileFileLink,
    ResourcesSectionTileVideoPreview
  },
  props: {
    topicData: {
      type: Object,
      required: true
    } as PropOptions<ResourceItem>
  },
  computed: {
    ...mapGetters({
      getApiUrl: GET_API_URL
    }),
    topicDownloadLink (): string {
      const path = URI_RESOURCES(this.topicData.id);
      return this.getApiUrl(path);
    },
    filesToDownload (): ResourceItemsFile[] {
      return this.topicData.files ? this.topicData.files.filter((file) => file.downloadable) : [];
    },
    filesToPreview (): ResourceItemsFile[] {
      return this.topicData.files ? this.topicData.files.filter((file) => !file.downloadable) : [];
    }
  }
});
