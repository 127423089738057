




































import Vue, { PropOptions } from 'vue';
import { mapGetters } from 'vuex';
import { NeIcon, NeModal, NeVideoPlayer, NeModalHeader, NeModalBody, NeIconButton } from '@ne/ne-vue2-lib';
import { ResourceItemsFile } from '@/api/models/resource';
import { URI_RESOURCES_STREAM } from '@/api/endpoints';
import { GET_API_URL } from '@/store/getters';

export default Vue.extend({
  name: 'ResourcesSectionTileVideoPreview',
  components: {
    NeIcon,
    NeModal,
    NeModalHeader,
    NeModalBody,
    NeIconButton,
    NeVideoPlayer
  },
  data () {
    return {
      isModalOpened: false
    };
  },
  props: {
    file: {
      type: Object,
      required: true
    } as PropOptions<ResourceItemsFile>
  },
  computed: {
    ...mapGetters({
      getApiUrl: GET_API_URL
    }),
    src (): string {
      const path = URI_RESOURCES_STREAM(this.file.id);
      return this.getApiUrl(path);
    }
  }
});
