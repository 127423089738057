







































import Vue from 'vue';
import { NeSection, NeSimpleGrid, NeSimpleGridItem } from '@ne/ne-vue2-lib';
import GoBackLink from '@/shared/components/GoBackLink/GoBackLink.vue';
import PublicationTile from '@/shared/components/PublicationTile/PublicationTile.vue';
import ResourceSectionTile from './components/SectionTile/SectionTile.vue';
import { ResourceItem } from '@/api/models/resource';
import { URI_RESOURCES_PAGE } from '@/api/endpoints';
import { RouteName } from '@/router/models';

const emptyChild: ResourceItem = { id: 0, name: '', uri: '', children: null, image: { default: '' }, size: 0 };

export default Vue.extend({
  name: 'Home',
  components: {
    NeSection,
    GoBackLink,
    PublicationTile,
    NeSimpleGrid,
    NeSimpleGridItem,
    ResourceSectionTile
  },
  data () {
    return {
      data: emptyChild
    };
  },
  computed: {
    pageTitle (): string {
      return this.data.name;
    },
    series (): ResourceItem {
      return this.data.children ? this.data.children[0] : emptyChild;
    },
    level (): ResourceItem {
      return this.series.children ? this.series.children[0] : emptyChild;
    },
    title (): ResourceItem {
      return this.level.children ? this.level.children[0] : emptyChild;
    }
  },
  methods: {
    async getResourceData (): Promise<void> {
      try {
        const key = `${this.$route.params.id.split(',').join('/')}`;
        this.data = (await this.$api.get<ResourceItem[]>(URI_RESOURCES_PAGE(key))).data[0];
      } catch (err) {
        this.$router.push({ name: RouteName.ERROR_404 });
      }
    }
  },
  created () {
    this.getResourceData();
  }
});
